.work-display-header {
    width: 100vw;
    height: 35rem;
    position: relative;
    /* background-color: red; */
}

.work-display-header img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* background-image: linear-gradient(to bottom, #0003, #000); */
}


.work-display-header-text {
    position: absolute;
    top: 50%;
    width: 75%;
    max-width: 80%;
    left: 50%;
    filter: drop-shadow(0px 0px 1rem rgba(206, 204, 204, 0.438));
    transform: translate(-50%, -50%);
    /* padding: 0px 5%; */
    text-align: center;
    color: #fff;
    /* text-shadow: 0 5px 10px #0004; */
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.842);
}

.work-display_header-heading,
.work-display_header-sub-heading1 {
    /* font-size: 5rem; */
    font-weight: bold;
    line-height: 1.3em;
    /* color: var(--base-color); */
}

.work-display_header-heading {
    font-size: 3.5rem;
}

.work-display_header-heading,
.work-display_header-sub-heading1,
.work-display_header-sub-heading2 {
    transform: translateY(5rem);
    opacity: 0;
    animation: textTransformAnimationY 1s forwards;
}

.work-display_header-sub-heading1 {
    animation-delay: .5s;
    color: var(--base-color);
    font-size: 3rem;
    /* margin-top: 2rem; */
}

.work-display_header-sub-heading2 {
    letter-spacing: 1px;
    animation-delay: 1s;
    padding: 0rem 10%;
    margin-top: 1rem;
    font-size: 1.6rem;
}

.project-padding-4rem {
    padding-bottom: 4rem;
}

.work-display-item-text p {
    font-size: 1.4rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    font-weight: 500;
    color: white;
    /* letter-spacing: .2rem;
    font-size: 1.2rem;
    color: rgb(223, 223, 223); */
    padding-right: 15%;
}

.work-display-item-text p,
.work-display-item-text h1 {
    width: 100%;
    padding-right: 0;
}

.display_image-container {
    width: 0%;
    height: 100vh;
    color: white;
    background-color: black;
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
}

.display_image-container.triggerDisplay {
    /* display: block; */
    z-index: 1020;
    width: 100vw;
    /* height: 100vh; */
    opacity: 1;
    transition: width .5s, opacity .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.imageScreen{
    width: 80%;
    /* border: 1px solid white; */
    height: 90vh;
    /* border-radius: 5rem; */
    overflow: hidden;
    position: relative;
}

.imageScreen img, video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.left-bar, .right-bar {
    background-color: white;
    color: black;
    width: 3rem;
    height: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.left-bar {
    border-radius: 0rem 1rem;
}
.right-bar {
    border-radius: 1rem 0rem;
}
.left-bar:hover,
.right-bar:hover {
    background-color: var(--base-color);
    /* color: black; */
}
.close-bar {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 5px solid var(--base-color);
    transition: transform .4s;
    box-shadow: 0px 0px 10px var(--base-color);
}

.close-bar:hover {
    transform: scale(1.05);
}

.close-bar::after, .close-bar::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
    border-radius: 3rem;
    height: 80%;
    background-color: var(--base-color);
    transition: all .5s;
}

.close-bar::before {
    transform: translate(-50%, -50%) rotate(40deg);
}

.close-bar::after {
    transform: translate(-50%, -50%) rotate(-40deg);
}

.close-bar:hover::before{
    transform: translate(-50%, -50%) rotate(-40deg);
}
.close-bar:hover::after {
    transform: translate(-50%, -50%) rotate(40deg);
}

/* .event-video {
    width: 85%;
    margin: 3rem auto 0rem auto;
    transform: translateY(5rem);
    opacity: 0;
    position: relative;
    z-index: 1;
    border-radius: 5rem;
    overflow: hidden;
} */
/* .event-item-grid {
    margin-top: 0;
} */
/* .event-video video{
    width: 100%;
    height: 100%;
} */

/* .event-text-center {
    text-align: center;
} */