.md_page-header .md_header-img {
    /* object-fit: contain; */
    object-position: top;
}

.md-heading-content-prinmary {
    color: white;
    text-align: center;
}

.md-heading-content-prinmary p {
    font-size: 2.8rem;
    text-shadow: 0 0px 10px rgba(255, 255, 255, 0.514);
    font-weight: bold;
    transform: translateY(10rem);
    opacity: 0;
}

.animation-box.textTransformAnimation p,
.animation-box.textTransformAnimation {
    animation: textTransformAnimationY 1s forwards;
}

.md-heading-content-prinmary p:last-child {
    font-size: 2.5rem;
    /* margin-top: .5rem; */
}

.md-content {
    /* border: 1px solid white; */
    width: 90%;
    /* padding: 10rem; */
    margin: 5rem auto 0rem auto;
    padding-bottom: 5rem;
}

.md-data {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 3rem;
    /* width: 120rem; */
    padding: 0rem 2rem;
    /* border: 1px solid white; */
}

.md-photo {
    width: 25%;
    height: max-content;
    /* border: 1px solid red; */

}

.md-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transform: translateY(10rem);
    /* height: max-content; */
}

/* .animation-box.textTransformAnimation {
    animation: textTransformAnimationY 1s forwards;
} */

.md-summary {
    width: 75%;
    /* height: 40rem; */
    /* border: 1px solid red; */
    height: max-content;
    /* padding: 2rem; */
}

.md-summary p {
    transform: translateY(10rem);
    color: white;
    text-align: justify;
    font-size: 1.5rem;
}

.md-about p {
    text-align: center;
    color: white;
    transform: translateY(10rem);
    opacity: 0;
}

.md-about p:first-child {
    font-weight: bold;
}