@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");


.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #040406;
  /* background: linear-gradient(to top, #040406 10%); */
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 1010;
  align-items: center;
  justify-content: center;
  color: var(--base-color);
  /* border: 1px solid red; */
}

#svg {
  height: 250px;
  width: 250px;
  stroke: #efb630;
  fill-opacity: 0;
  stroke-width: 3px;
  stroke-dasharray: 4500;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid white; */
  animation: draw 8s ease;
}

text {
  font-size: 9rem;
  font-family: "Michroma", sans-serif;
  transform: translateX(-25%);
  text-shadow: 0rem 0rem 3rem #efb630;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 4500;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.name-container {
  height: 30px;
  overflow: hidden;
}

.logo-name {
  color: #fff;
  font-size: 20px;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: bolder;
}
