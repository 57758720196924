.stakeholders_page-header .stakeholders_header-img {
    object-fit: cover;
}

.stakeholder-data-container {
    padding-bottom: 5rem;
}

.heading-style {
    text-align: center;
    font-size: 4.5rem;
    letter-spacing: 2px;
    transform: translateY(10rem);
    opacity: 0;
    color: #fff;
    text-shadow: 0 0px 10px rgb;
}

.stakeholder-all-data {
    width: 90%;
    height: max-content;
    margin: auto;
    padding: 0rem 2rem;
    margin-top: 0rem;
}
.stakeholder-main-heading:not(:first-child){
    margin-top: 2rem;
}

.stakeholder-main-heading p {
    color: white;
    text-align: center;
    font-weight: 600;
    width: 90%;
    margin: auto;
    opacity: 0;
    transform: translateY(5rem);
}
.text-magrin-bottom-2rem{
    margin-bottom: 2rem;
}

.stakeholder-data-box:not(:first-child) {
    margin-top: 3rem;
}

.stakeholder-data-heading .heading-style {
    font-size: 3.5rem;
    text-shadow: none;
    font-weight: 500;
}

.stakeholder-data {
    color: white;
    display: flex;
    margin-top: 2rem;
    transform: translateY(10rem);
    opacity: 0;
    background-color: black;
}

.heading-style.textTransformAnimation,
.stakeholder-data.textTransformAnimation {
    animation: textTransformAnimationY 1s forwards;
}

.stakeholder-data ul li button {
    position: relative;
    z-index: 1;
    padding: 2rem;
    color: white;
    width: 100%;
    outline: none;
    text-align: start;
    border: none;
    font-size: 1.7rem;
    background-color: transparent;
}

.stakeholder-data ul li button.focusButton {
    background-color: var(--base-color);
    transition: all .3s;
}

.stakeholder-data ul li{
    border: 1px solid white;
}

.stakeholder-data-right table tbody td:first-child{
    width: 50%;
}

.stakeholder-data-left {
    width: 30%;
    background-color: rgb(0, 0, 0);
    box-shadow: 1px 1px 10px rgb(61, 61, 61) inset;
}


.stakeholder-data-right, .stakeholder-data-left{
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
}
.stakeholder-data-left::-webkit-scrollbar,
.stakeholder-data-right::-webkit-scrollbar{
    width: 0px;
}

.stakeholder-data-right {
    width: 70%;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, var(--base-color), white, var(--base-color)) 3;
    padding: 2rem;
    transition: all .5s;
    margin-left: -4px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,td {
    padding: 1.5rem 2rem;
    border: 1px solid rgba(255, 255, 255, 0.61);
}

th {
    font-weight: bold;
    text-align: start;
}

th:not(:last-child) {
    border-right: 2px solid rgb(255, 255, 255);
}

.stakeholder-data table thead {
    background-color: var(--base-color);
}

.stakeholder-data table tr {
    border-collapse: collapse;

}

.table-first-child {
    display: none;
}

.team-data {
    color: white;
    width: 80%;
    margin: 5rem auto;
    /* border: 1px solid white; */
}

.director-data li {
    /* line-height: 5rem; */
    margin-top: 2rem;
}

/* .director-data{
    margin-top: 1rem;
}   */

.director-guideline-text{
    margin-top: 5rem;
}

.cmd-team-image {
    width: 100%;
    margin-top: 5rem;
}

/* .our-cluent-margin-top {
    margin-top: 3rem;
} */

.cmd-team-image img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}

.our-client-body table tbody tr td:first-child{
    width: 100%;
}
.animation-text,
.cmd-team-image img {
    transform: translateY(7rem);
    opacity: 0;
    /* border: 1px solid white; */
}

.animation-text {
    /* border: 1px solid rgba(255, 255, 255, 0.199); */
    padding: .5rem;
    /* border-radius: 1rem; */
    /* background-color: rgba(29, 29, 29, 0.096); */
    width: 70%;
    margin: auto;
    color: white;
}

.animation-text:not(:first-child) {
    margin: 2rem auto 0rem auto;
}

.animation-text li strong {
    color: var(--base-color);
}

.team-data .animation-text strong {
    /* transform: translateY(10rem); */
    color: var(--base-color);
    /* margin-top: 10rem; */
}

.no-border {
    border: none;
}

.animation-text.textTransformAnimation,
.cmd-team-image.textTransformAnimation img {
    animation: textTransformAnimationY 1s forwards;
}

