.jobFormContainer {
    width: 50%;
    margin: 5rem auto;
}

.formHeading {
    text-align: center;
    font-size: 4rem;
    letter-spacing: .2rem;
    font-weight: bold;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    margin: 5rem 0rem 4rem 0rem;
}

.input-row:not(:first-child) {
    margin-top: 2rem;
}

.input-label {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.input-data {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 1rem;
    border: 1px solid darkgray;
    padding: .5rem;
    border-radius: .5rem;
}

.input-heading {
    font-weight: bold;
    font-size: 1.6rem;
    color: white;
}

.job-input-field {
    padding: .7rem;
    outline: none;
    border: none;
    font-size: 1.5rem;
    /* background-color: #e0e0e0d5; */
    background-color: rgb(236, 242, 243);
    border-radius: .5rem;
    font-weight: bold;
    /* color: rgb(95, 95, 95); */
    color: black;
}

.input-data label {
    color: white;
    font-size: 1.3rem;
}

.bullet {
    font-size: 1.5rem;
    color: white;
}

.input-data textarea {
    background-color: rgb(236, 242, 243);
    color: black;
    font-weight: 500;
}

.select-data {
    border: none;
    border-radius: .5rem;
    padding: .7rem;
    outline: none;
    font-weight: bold;
    color: rgb(95, 95, 95);
}

.select-data option {
    font-weight: bold;
    color: rgb(95, 95, 95);
}

.secondary-label {
    margin-top: 1rem;
}

.secondary-label p {
    font-size: 1.3rem;
    color: rgb(224, 224, 224);
}

.education-area {
    border: 1px solid rgba(128, 128, 128, 0.295);
    padding: .5rem;
    border-radius: .5rem;
}

.important-info {
    font-size: 1.3rem;
    border: 1px solid var(--base-color);
    border-radius: .5rem;
    padding: 1rem;
}

.jobForm {
    margin-top: 3rem;
    position: relative;
    overflow: hidden;
}

.makeFormInvisible {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.822);
    display: none;
}

.important-info ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.important-info ul li {
    white-space: nowrap;
    color: var(--base-color);
    display: flex;
    align-items: center;
    gap: .7rem;
}

.important-info h2 {
    margin-bottom: .5rem;
    color: white;
    font-weight: 500;
}

.captcha-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;
}

.update-captcha-btn:hover {
    color: black;
    border-color: black;
}

.enter-captch-container {
    height: 3.5rem;
    width: 25rem;
}

[name="get_captcha_code"] {
    width: 100%;
    height: 100%;
    padding: 0rem 1rem;
    border: 1px solid gray;
    outline: none;
}

.fa-triangle-exclamation {
    font-size: 2rem;
}

.error-msg {
    display: flex;
    color: white;
    background-color: green;
    font-size: 1.7rem;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    margin-top: 1rem;
}

.submit-btn-container {
    margin-top: 1rem;
    position: relative;
    height: 5rem;
}

.job-submit-btn {
    min-width: 10rem;
    width: max-content;
    height: 3.5rem;
    padding: .8rem 1.2rem;
    outline: none;
    border: 3px solid var(--base-color);
    letter-spacing: .1rem;
    position: absolute;
    right: 0;
    z-index: 1;
    border-radius: 30rem;
    display: flex;
    gap: 1rem;
    z-index: 1001;
    align-items: center;
    justify-content: center;
    transition: transform .2s;
}

.circleJobSubmitButton {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    animation: animateCircleJobSubmitButton 1s  infinite;
}

@keyframes animateCircleJobSubmitButton {
    100% {
        transform: rotate(360deg);
    }
}

.job-submit-btn::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 100%;
    opacity: 0;
    background-color: var(--base-color);
    border-radius: 50%;
    left: 0;
    top: 0;
    transition: width .5s;
    border-radius: 30rem;
    z-index: -1;
}

.job-submit-btn:hover::before {
    opacity: 1;
    width: 100%;
    height: 100%;
}

/* .job-submit-btn:hover {
    border-color: white;
    transform: scale(1.04, 1.04);
} */

.job-submit-btn:active {
    transform: scale(.9, .9);
}

.error-msg {
    display: none;
}

.not-found-page {
    height: calc(100vh - 5.6rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    color: rgb(75, 75, 75);
}

.err-code {
    font-size: 12rem;
    letter-spacing: 2rem;
}

.not-found {
    font-size: 3rem;
    font-weight: bold;
}

.not-found-msg {
    letter-spacing: .1rem;
}

.display_image {
    height: 100%;
    /* border: 1px solid black; */
    width: 100%;
    /* border: 1px solid green; */
    display: flex;
}

.display_image img,
.display_image embed {
    width: 9rem;
    height: 12rem;
}

.fa-circle-info {
    margin: 0rem .5rem;
    color: var(--base-color);
}

/* .signature_image {
    width: 15rem;
    height: 5rem;
} */

.show-image-err-msg {
    /* border: 1px solid red; */
    /* display: block; */
    width: 100%;
    position: relative;
}

.show-image-err-msg p {
    color: var(--base-color);
    position: absolute;
    left: calc(50% - 4.5rem);
    top: 50%;
    font-weight: bold;
    transform: translate(-50%, -50%);
}
