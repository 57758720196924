.career_page {
    padding-bottom: 10rem;
}

.applyForJob_page {
    padding-bottom: 7rem;
}

.career-summary {
    width: 70%;
    color: white;
    margin: 3rem auto;
}

.career-job-btn-conatiner {
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.career-summary p,
.career-job-btn-conatiner a {
    transform: translateY(10rem);
    opacity: 0;
}

.career-summary.textTransformAnimation p,
.career-job-btn-conatiner.textTransformAnimation a {
    animation: textTransformAnimationY 1s forwards;
}

.company-address {
    text-align: center;
    color: white;
    transform: translateY(10rem);
    opacity: 0;
}

.show-description-heading.textTransformAnimation p {
    animation: textTransformAnimationY 1s forwards;
    animation-delay: .5s;
}

.job-guideline-box {
    width: 75%;
    margin: 5rem auto 0rem auto;
    opacity: 0;
    transform: translateY(10rem);
}

.job-guideline-box.textTransformAnimation {
    animation: textTransformAnimationY 1s forwards;
    animation-delay: 1s;
}

.job-guideline-navigate-btn {
    border-bottom: 2px solid var(--base-color);
    padding-left: 1rem;
    display: flex;
    justify-content: center;
}

.job-guideline-navigate-btn-style {
    width: 100%;
    padding: 1rem;
    border-left: 2px solid var(--base-color);
    border-right: 2px solid var(--base-color);
}

.job-data-container {
    position: relative;
    width: 98%;
    margin: auto;
}

.gd-image {
    width: 100%;
    height: max-content;
}

.gd-image img {
    width: 100%;
    height: max-content;
}

.job-guideline-data {
    color: white;
    text-align: center;
}

.gd-heading h2 {
    font-weight: 500;
}

.gd-description {
    margin: 1rem 0rem;
    font-size: 1.4rem;
    letter-spacing: .5px;
    padding: 0rem 2rem;
    text-align: start;
}

.post-container {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 4rem;
    /* border: 1px solid white; */
}

.form-container {
    flex-direction: column;
    position: relative;
}

.animation-div {
    display: none;
}

.animation-div {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.829);
    z-index: 1000;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.animation-div p {
    font-size: 4rem;
    font-weight: bold;
    display: none;
}

.form .circle-animation {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border-bottom: 2px solid var(--base-color);
    border-left: 5px solid var(--base-color);
    border-top: 5px solid var(--base-color);
    animation: animate-buffer 1s infinite;
}

.form {
    height: max-content;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.329);
    border-radius: 10px;
    backdrop-filter: blur(10px);
    box-shadow: 0px 5px 10px var(--base-color);
    padding: 50px 35px;
    position: relative;
    overflow: hidden;
}

/* .form button:hover {
    transition: all .2s;
    color: var(--base-color);
} */

.form label,
.form input,
.form button,
.form h3 {
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
}

.form h3 {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
}

.form label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
}

.form input {
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
}

.form input::placeholder {
    color: #e5e5e5;
}

.submit-btn {
    margin-top: 50px;
    width: 100%;
    background-color: var(--base-color);
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.submit-btn:hover {
    color: #080710;
    transition: all .3s;
}

.cicle-animation {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    border: 50%;

}

.form-others-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    /* border: 1px solid red; */
    display: flex;
    align-items: space-between;
}

.form-others-btn button {
    color: var(--base-color);
    background-color: transparent;
    text-decoration: underline;
}

.form button:hover:not(.submit-btn, .verify-email button) {
    color: #efb630b0;
}

.register-form {
    height: max-content;

}

.warning-msg {
    text-align: center;
    margin-top: 1rem;
    color: white;
}

.all-jobs {
    display: none;
}

.form-box {
    display: flex;
    margin-top: 8px;
    height: 50px;
    /* justify-content: space-between; */
    align-items: center;
    /* border: 1px solid red; */
}

.form-box .show-password,
.form-box input {
    height: 100%;
}

.form-box input {
    margin-top: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.show-password {
    position: relative;
    /* height: 5rem; */
    height: 50px;
    width: 5rem;
    background-color: rgba(255, 255, 255, 0.07);
    /* border: 1px solid red; */
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.show-password i {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-size: 2rem;
    color: white;
}

.show-password i:hover {
    cursor: pointer;
}

.password-show {
    display: none;
}

.ad-gd-pdf {
    width: 100%;
    height: 700px;
    /* display: none; */
}

.showpdf {
    display: none;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--base-color);
    font-size: 2.5rem;
    text-decoration: underline;
}

.verify-email {
    border-radius: .3rem;
    display: flex;
    justify-content: end;
    width: 100%;
    overflow: hidden;
    padding-top: 1rem;
}

.verify-email button {
    background-color: var(--base-color);
    padding: 1rem;
    border-radius: .3rem;
    display: inline-block;
}