.notice-data-container{
    flex-direction: row-reverse;
}

.notice-data-left {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    gap: 1rem;
    width: 100%;
}

.notice-data-container{
    padding: 2rem;
}
.notice-media iframe {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.other-notice {
    margin: -7rem auto 0rem auto;
    padding: 2rem 0rem;
    color: white;
    font-weight: bold;
    width: 90%;
    font-size: 3rem;
}

.notice-data-left img {
    width: 5rem;
}

.less-margin-for-mobile-2rem {
    margin-top: -2rem;
}