.error,
.back-home {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.section {
    padding: 4rem 2rem;
    text-align: center;
}

.section .error {
    font-size: 150px;
    color: #efb630;
    text-shadow:
        1px 1px 1px #efb630,
        2px 2px 1px #f1b425,
        3px 3px 1px #f0b01c,
        4px 4px 1px #ffb300,
        5px 5px 1px #efb630,
        6px 6px 1px #efb630,
        7px 7px 1px #efb630,
        8px 8px 1px #efb630,
        25px 25px 8px rgba(0, 0, 0, 0.2);
}

.page {
    margin: 2rem 0;
    font-size: 20px;
    font-weight: 600;
    color: #444;
}

.back-home {
    display: inline-block;
    border: 2px solid #222;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.75rem 1rem 0.6rem;
    transition: all 0.2s linear;
    box-shadow: 0 15px 15px -11px rgba(0, 0, 0, 0.4);
    background: #222;
    border-radius: 6px;
}

.back-home:hover {
    background: #222;
    color: #ddd;
}