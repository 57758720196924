.tender-archive-infobar {
    /* border: 1px solid white; */
    padding-bottom: 10rem;
    /* color: white; */
    position: relative;
}

.tender-data-container {
    display: flex;
    width: 90%;
    margin: auto;
    border-width: 4px;
    border-style: solid;
    box-shadow: 0rem .1rem .3rem rgba(128, 128, 128, 0.144);
    padding: 2rem 3rem;
    transform: translateY(5rem);
    opacity: 0;
    transition: background .3s;
}

.tender-data-msg-primary p {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--base-color);
}

.tender-data-container:hover {
    background-color: rgb(12, 12, 12);
}

.tender-padding {
    padding-bottom: 4rem;
}

.tender-data-container.textTransformAnimation {
    animation: textTransformAnimationY 1s ease forwards;
}

.tender-data-container:nth-child(odd) {
    border-image: linear-gradient(to left, var(--base-color), white, rgb(255, 145, 0)) 30;
}

.tender-data-container:nth-child(even) {
    border-image: linear-gradient(to right, var(--base-color), white, rgb(255, 145, 0)) 30;
}

.tender-data-container:not(:last-child) {
    margin-bottom: 3rem;
}

.count-tender-data-count {
    font-size: 3rem;
    color: var(--base-color);
    font-weight: bold;
    display: inline-block;
    padding-bottom: 1rem;
    border-bottom: 3px solid var(--base-color);
    margin-bottom: 5rem;
}

.tender-data-left {
    width: 35%;
}

.tender-submission-title {
    color: white;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: .1rem;
}

.tender-submission-title-first {
    letter-spacing: .2rem;
}

.tender-submission-title-second {
    font-size: 1.4rem;
    color: rgb(185, 185, 185);
}

.tender-data-right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 65%;
    line-height: 2.5rem;
}

.tender-data-msg-secondary p {
    font-size: 1.3rem;
    color: rgb(255, 255, 255);
}

.tenderRelatableLink {
    font-size: 1.2rem;
    letter-spacing: .1rem;
    margin-left: 3rem;
    color: white;
    font-weight: bold;
    text-decoration: underline;
    display: inline-block;
    transition: transform .1s;
}

.tenderRelatableLink:active {
    color: white;
}

.tenderRelatableLink:hover {
    transform: scale(1.01) translateY(-.1rem);
}