/*here is some home css animation*/
@keyframes showContent {
    100% {
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes showImage {
    to {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

@keyframes showThumbnail {
    from {
        width: 0;
        opacity: 0;
    }
}

@keyframes effectNext {
    from {
        transform: translateX(150px);
    }
}

@keyframes outFrame {
    to {
        width: 15rem;
        height: 22rem;
        bottom: 5rem;
        left: 50%;
        border-radius: 20px;
    }
}

@keyframes contentOut {
    to {
        transform: translateY(-15rem);
        filter: blur(20px);
        opacity: 0;
    }
}

/*here is home related animation closed*/

@keyframes textTransformAnimationY {
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes textTransformAnimationX {
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/*contact page animation*/
@keyframes animateContactForm {
    to {
        transform: translateY(-45%);
        opacity: 1;
    }
}
/*finished*/