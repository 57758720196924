@media screen and (max-width: 1800px) {
    .captcha-container {
        flex-direction: column !important;
        align-items: flex-end !important;
    }

    .showMsg {
        margin-top: 2rem;
    }
}

/*media query for any devices*/
@media screen and (max-width: 1360px) {

    /*for home header aroow button*/
    .nextPrevArrows {
        width: 75% !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        max-width: 75% !important;
        bottom: 0 !important;
    }

    /*finished*/

    /*for grid item*/
    .work-display-item-grid {
        padding: 0rem 3rem 0rem 3rem !important;
    }

    /*finished*/
    .event-video {
        margin: 2rem auto 0rem auto !important;
        width: 100% !important;
    }
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 53% !important;
    }

    /*for notice link*/
    .home-noticeLink-container {
        display: none !important;
    }

    /*finished*/

}

@media screen and (max-width: 1025px) {

    /*for home page first content*/
    .slider .list .item .content .title,
    .slider .list .item .content .type {
        white-space: wrap !important;
    }

    .slider .list .item .content {
        width: 90% !important;
    }

    .slider .list .item .content {
        top: 10% !important;
        padding-right: 0 !important;
    }

    /*finished*/

    /*for tender*/
    .tender-data-container {
        padding: 2.5rem 3rem !important;
        gap: 1rem !important;
    }

    .tender-data-right {
        gap: 2rem !important;
    }

    .tender-data-msg-primary p {
        font-size: 1.6rem !important;
    }

    .tender-data-msg-secondary p {
        font-size: 1.4rem !important;
    }

    .tender-submission-title {
        font-size: 1.3rem !important;
    }

    /*finish*/

    /*for home page second content*/
    .home-about_cmd-down {
        flex-direction: column-reverse !important;
        padding-bottom: 5rem !important;
    }

    .home-about_cmd-left,
    .home-about_cmd-right {
        width: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .home-about_cmd-right img {
        width: 65% !important;
    }

    .home-about_cmd-left p {
        font-size: 18px !important;
        width: 90% !important;
    }

    /*finished*/
    /*for home quick link*/
    .home-noticeLink-container {
        display: none !important;
    }

    /*finished*/

    /*for grid item*/
    .work-display-item-grid {
        grid-template-columns: repeat(auto-fit, minmax(48rem, 48rem)) !important;
    }

    .work-display-item {
        height: 30rem !important;
    }

    /*finished*/

    /*footer quicklinks*/
    .quicklink-logo svg {
        width: 40px !important;
        height: 40px !important;
    }

    /*finished*/

    /*for contact us page*/
    .contact_content {
        flex-direction: column;
        width: 70% !important;
    }

    .downPart {
        margin-top: 3rem !important;
    }

    .contactUs_header-text {
        width: 100% !important;
    }

    .contactUs_header-sub-heading1,
    .contactUs_header-heading {
        white-space: nowrap !important;
    }

    .contact-form {
        width: 100% !important;
    }

    .contactUs_header-sub-heading2,
    .contactUs_header-sub-links {
        display: none !important;
    }

    /*finished*/

    /*for notice page*/
    .notice_page-notification-container {
        flex-direction: column !important;
        /* border: 1px solid white; */
    }

    .notice_page-notification-box {
        width: 100% !important;
    }

    .notice_page-notification-box:not(:first-child),
    .notice_page-notification-box:not(:last-child) {
        border: none !important;
    }

    .notice_page-notification-box-heading {
        text-align: start !important;
        font-size: 1.3rem !important;
    }

    /*finished*/
    .stakeholder-all-data {
        width: 100% !important;
        /* border: 1px solid red; */
    }

    
    .home-about_cmd_container.textTransformAnimation .home-about_cmd-down .home-about_cmd-right {
        animation-delay: 0s ;
    }
    
    .home-about_cmd_container.textTransformAnimation .home-about_cmd-down .home-about_cmd-left {
        animation-delay: 1s;
    }
    .home-about_cmd_container.textTransformAnimation .home-about_cmd-left {
        transform: translateX(100%);
    }

    .home-about_cmd_container.textTransformAnimation .home-about_cmd-right {
        transform: translateX(-100%);
    }

    .jobFormContainer {
        width: 70% !important;
    }
}

@media screen and (max-width: 900px) {

    /*for grid containers*/
    .work-display-item-grid {
        grid-template-columns: repeat(auto-fill, minmax(48rem, 1fr)) !important;
    }

    .work-display-item {
        height: 45rem !important;
    }

    /*finished*/

    /*for about-us page*/
    .show-description-summary {
        width: 90% !important;
    }

    .about_us-rules-button {
        font-size: 1.5rem !important;
    }

    /*finished*/
    /*for tender*/
    .tender-data-container {
        /* padding: 2.5rem 3rem; */
        /* gap: 1rem; */
        flex-direction: column !important;
        border: none !important;
        border-bottom: 1px solid transparent !important;
        border-image: linear-gradient(to right, var(--base-color), white, yellow) 30 !important;
        padding: 0rem 0rem 1rem 0rem !important;
    }

    .count-tender-data-count {
        margin-bottom: 2rem !important;
    }

    .tender-data-right,
    .tender-data-left {
        width: 100% !important;
    }

    .tender-data-right {
        gap: 2rem !important;
    }

    .tender-submission-title {
        font-size: 1.3rem !important;
    }

    /*finish*/
    .stakeholder-data-heading .heading-style {
        font-size: 3rem !important;
    }

    .notice-data-container {
        flex-direction: column-reverse !important;
    }

    .notice-data-left {
        align-items: flex-start !important;
    }

    .notice-media {
        /* border: 1px solid red; */
        width: 70% !important;
        height: 10rem !important;
    }

    .other-notice {
        font-size: 2rem !important;
        margin-bottom: 2rem !important;
    }

    .other-notice p {
        text-decoration: underline !important;
    }

    .formHeading {
        margin: 1rem 0rem !important;
    }
}

@media screen and (max-width: 750px) {

    /*for header*/
    .stakeholder-data-heading .heading-style {
        font-size: 2.5rem !important;
    }

    /*finished*/

    /*for grid container*/
    .work-display-item-grid {
        grid-template-columns: repeat(auto-fill, minmax(48rem, 1fr)) !important;
    }

    .work-display-item {
        height: 35rem !important;
    }

    .work-display-header {
        height: 30rem !important;
    }

    .work-display-header-text {
        width: 98% !important;
        max-width: 98% !important;
        /* border: 1px solid white; */
    }

    .work-display_header-heading {
        font-size: 2.5rem !important;
    }

    .work-display_header-sub-heading1 {
        font-size: 3rem !important;
    }

    /*finished*/

    /*for universal display margin*/
    /* .display-margin-top-5rem {
        margin-top: -7rem;
    } */
    /*finished*/

    /*for header brand name*/
    .cmd-brand-name {
        font-size: 3rem !important;
    }

    .cmd-brand-text1 {
        font-size: .8rem !important;
    }

    /*finished*/

    /*for contact us page*/
    .downPart {
        margin-top: 3rem !important;
    }

    .contact_content {
        width: 80% !important;
        top: 50% !important;
    }

    /*finished*/

    /*for footer quicklink*/
    .quicklink-logo svg {
        width: 30px !important;
        height: 30px !important;
    }

    .quicklink-logo-container {
        gap: 2rem !important;
    }

    /*finished*/

    /*for show product and project page*/
    .showProduct-summary {
        width: 80% !important;
    }

    .showProduct_header-sub-heading1,
    .showProduct_header-sub-heading2 {
        margin-top: .5rem !important;
    }

    .showProduct-container {
        margin-top: -5rem !important;
    }

    /*finished*/

    /*for md page*/
    .md-data {
        flex-direction: column !important;
        align-items: center !important;
    }

    .md-photo {
        /* border: 1px solid white; */
        width: 80% !important;
    }

    .md-summary {
        width: 90% !important;
    }

    /*finished*/
    .stakeholder-data-right {
        padding: 1rem !important;
    }

    .stakeholder-data {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .stakeholder-data-right,
    .stakeholder-data-left {
        overflow-y: hidden !important;
        max-height: max-content !important;
        width: 100% !important;
    }

    .stakeholder-data-right {
        border: 1px solid var(--base-color) !important;
    }

    .stakeholder-data-left {
        display: flex !important;
        flex-direction: row !important;
        overflow-y: hidden !important;
        overflow-x: scroll !important;
    }

    .stakeholder-data-left li button {
        white-space: nowrap !important;
    }

    .stakeholder-data-left::-webkit-scrollbar {
        height: 0 !important;
    }

    .formHeading p {
        font-size: 3rem !important;
    }

    .gd-heading h2 {
        font-size: 1.7rem !important;
        font-weight: 700 !important;
    }
}

@media screen and (max-width: 630px) {

    /* .work-display-item-grid {
        he
    } */

    /*for contact us page*/
    .contactUs_header-sub-heading1,
    .contactUs_header-sub-heading2 {
        font-size: 4rem !important;
    }

    .contact_content {
        gap: 1rem !important;
    }

    /*finished*/

    /*for career page*/
    .career-summary {
        width: 90% !important;
    }

    .career-heading h1 {
        font-size: 2.2rem !important;
    }

    /*finished*/

    /*for apply-for-job page*/
    .job-guideline-box {
        /* border: 1px solid white; */
        width: 98% !important;
    }

    /*finished*/
    /*for universal margin*/
    .less-margin-for-mobile {
        margin-top: -5rem !important;
    }

    /*finished*/
    /*for about-us page*/
    .about_us-rules-button {
        font-size: 1.2rem !important;
    }

    /*finished*/

    .notice_page-notification-container {
        width: 90% !important;
    }

    .imageScreen {
        width: 100% !important;
        position: absolute !important;
        height: 80vh !important;
        left: 0 !important;
    }

    .left-bar,
    .right-bar {
        padding: 0rem 1.5rem !important;
        position: absolute !important;
        z-index: 1 !important;
        background-color: rgba(0, 0, 0, 0.541) !important;
        color: white !important;
        border: 1px solid white !important;
    }

    .left-bar {
        left: 0 !important;
    }

    .right-bar {
        right: 0 !important;
    }

    .work-display-item img {
        opacity: 1 !important;
    }

    .event-not-found-text {
        white-space: nowrap !important;
        padding-top: 5rem !important;
        font-size: 2rem !important;
    }

    .tender-archive-infobar,
    .showProduct_page {
        padding-bottom: 5rem !important;
    }

    .tender-archive-infobar {
        margin-top: 3rem !important;
    }

    .custom-no-data-found,
    .data-not-found {
        font-size: 2rem !important;
    }

    .other-notice {
        margin: auto !important;
        padding-bottom: 3rem !important;
    }

    .searchContainer {
        padding-bottom: 4rem !important;
        justify-content: center !important;
    }

    .jobFormContainer {
        width: 80% !important;
    }

    .padding-6rem,
    .padding-10rem {
        padding-bottom: 9rem !important;
    }

    .tender-padding {
        padding-bottom: 0rem;
    }
}

@media screen and (max-width: 500px) {
    .showProduct-secondary-image img {
        border-radius: 0 !important;
    }

    .show-image-err-msg p {
        font-size: 1.5rem !important;
    }

    .jobForm {
        margin-top: 0 !important;
    }

    .input-heading {
        font-weight: 700 !important;
    }

    /*for header brand name*/
    /*finished*/
    /* .navbar {

    } */
    .slider .list .item .content {
        padding-right: 1rem !important;
    }

    /*for show project and product page*/
    .showProduct-summary {
        line-height: 2.2rem !important;
    }

    .showProduct-summary,
    .showProduct-secondary-image {
        width: 90% !important;
    }

    .details .detail-heading {
        font-size: 2rem !important;
    }

    /*finished*/

    /*for home page */
    .home-about_cmd-right img {
        width: 40rem !important;
    }

    .home-about_cmd-left p {
        font-size: 1.6rem !important;
        text-align: justify !important;
    }

    .home-about_cmd_container {
        padding: 0 !important;
    }

    .home-about_cmd-left p {
        line-height: 2.3rem !important;
        /* text-align: ; */
    }

    .home-recent_projects {
        margin-top: 0 !important;
    }

    /* .cmd-page-secondary-heading h1 {
        font-size: 3.5rem;
    } */
    /*finished*/

    /*for grid items*/

    /* .event-video {
        margin-top: 5rem;
        height: 100%;
        border-radius: 0;
    }

    .event-video video {
        object-position: center;
        object-fit: cover;
    }

    .event-grid-less-margin {
        margin-top: 0;
    } */

    .work-display-item {
        border-bottom: 1px solid rgb(153, 153, 153) !important;
        height: 31rem !important;
    }

    .work-display-item_img img {
        opacity: .5 !important;
    }

    .work-display_header-sub-heading2 {
        display: none !important;
    }

    .work-display-header {
        height: 30vh !important;
    }

    .work-display_header-sub-heading1 {
        margin-top: 1rem !important;
        font-size: 2.5rem !important;
    }

    .work-display-item-text h1 {
        line-height: 2.4rem !important;
    }

    .work-display-item-text p {
        margin-top: .5rem !important;
    }

    .work-display-item-grid {
        gap: 0 !important;
        grid-template-columns: repeat(auto-fill, minmax(43rem, 1fr)) !important;
        padding: 0 0 5rem 0rem !important;
    }


    /*finished*/

    /*for footer*/
    .footer_company_provirder {
        letter-spacing: 1px !important;
    }

    .footer_quicklink ul li a,
    .fourthRow li {
        font-size: 1.3rem !important;
    }

    .quicklink-logo svg {
        width: 30px !important;
        height: 30px !important;
    }

    .quicklink-logo-txt {
        font-size: 1.2rem !important;
    }

    .footer_quicklink ul {
        padding: 1.5rem 3rem !important;
        align-items: center !important;
    }

    .footer_quicklink {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .footer_quicklink ul:first-child {
        padding-top: 0 !important;
    }

    .footer_quicklink ul:last-child {
        width: 100% !important;
    }

    .quicklink-logo-container {
        flex-direction: column !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.247) !important;
        padding-bottom: 1rem !important;
    }

    .quicklink-logo_box-margin-left {
        margin-left: 0 !important;
    }

    /*finished*/

    /*for contact page*/
    .contactHeading h3 {
        border-bottom: none !important;
        font-size: 2rem !important;
        text-align: center !important;
    }

    .contact_content {
        width: 90% !important;
    }

    .contactUs_header-heading,
    .contactUs_header-sub-heading1 {
        font-size: 3.5rem !important;
    }

    .contactUs_page-header {
        height: 80vh !important;
    }

    /*finished*/

    /*for project navigate button*/
    .ongoingCompleted-buttons {
        gap: 1.5rem !important;
    }

    /*finished*/
    /*for about-us page*/
    .about_us-rules-button {
        font-size: 1.2rem !important;
        padding: 1rem .7rem !important;
    }

    /*finished*/

    /*for md page*/
    .md-data {
        flex-direction: column !important;
        align-items: center !important;
        /* border: 1px solid white; */
    }

    .md-content {
        width: 100% !important;
    }

    .md-photo {
        /* border: 1px solid white; */
        width: 100% !important;
    }

    .md-summary {
        width: 100% !important;
    }

    .md-heading-content-prinmary p {
        font-size: 2rem !important;
    }

    .md-heading-content-prinmary p:last-child {
        font-size: 1.8rem !important;
    }

    .about_us-heading h1 {
        font-size: 3rem !important;
    }

    /*finished*/

    /*for stakeholder*/
    .team-data {
        width: 93% !important;
    }

    /*finished*/
    .notice_page-notification-container {
        width: 95% !important;
    }

    .downPart {
        margin-top: 1rem !important;
    }

    .show-description-heading h1 {
        font-size: 2.5rem !important;
    }

    .stakeholder-all-data {
        padding: .5rem !important;
    }

    th,
    td {
        padding: 1rem !important;
    }

    .stakeholder-data ul li button {
        padding: 1.5rem 1rem !important;
        font-weight: bold !important;
        /* color: ; */
    }

    .stakeholder-data-right {
        padding: .1rem !important;
    }

    .stakeholder-data-heading .heading-style {
        font-size: 1.5rem !important;
    }

    .stakeholder-data {
        margin-top: .5rem !important;
    }

    .stakeholder-main-heading .heading-style {
        margin-bottom: 2rem !important;
    }

    .animation-text {
        width: 98% !important;
    }

    .stakeholder-officer-heading {
        margin-top: 3rem !important;
    }

    .notice-btn span:last-child {
        font-size: 1.3rem !important;
    }

    .section .error {
        font-size: 12rem !important;
    }

    .jobFormContainer {
        width: 93% !important;
    }

    .jobFormContainer {
        margin: 1rem auto !important;
    }

    .formHeading p {
        font-size: 2.5rem !important;
    }

    .important-info ul li {
        overflow-wrap: break-word !important;
        white-space: normal !important;
        /* Override nowrap to allow wrapping */
    }

    .form {
        width: 100% !important;
        padding: 30px 20px !important;
    }

    .form label {
        margin-top: 20px !important;
    }

    .submit-btn {
        margin-top: 30px !important;
    }

    .ad-gd-pdf {
        display: none !important;
    }

    .showpdf {
        display: inline-block !important;
    }

    .gd-heading h2 {
        font-size: 1.5rem !important;
        font-weight: 900 !important;
        /* text-align: start; */
    }

    .gd-image button {
        font-size: 2rem !important;
    }
}

@media screen and (max-width: 400px) {

    /*for grid items*/
    .work-display-item-grid {
        grid-template-columns: repeat(auto-fill, minmax(43rem, 1fr)) !important;
    }

    /*finished*/

    /*for project navigate button*/
    .ongoingCompleted-buttons {
        gap: 1rem;
    }

    /*finished*/

    /*for contact page*/
    /* .contactUs_page-header {
        height: 100vh;
    } */
    .contactUs_header-heading,
    .contactUs_header-sub-heading1 {
        font-size: 3rem;
    }

    /*finished*/
    /* .work-display-item-text h1{
        font-size: 3rem;
        line-height: 3.5rem;
    } */
    .work-display_header-heading {
        font-size: 2.5rem !important;
    }

    .work-display_header-sub-heading1 {
        margin-top: 0 !important;
        font-size: 2rem !important;
    }

    .work-display_header-sub-heading2 {
        font-size: 1.3rem !important;
    }

    .work-display-item {
        height: 28rem !important;
    }

    .about_us-rules-button {
        font-size: 1rem !important;
    }

    .ongoingCompleted-buttons button {
        padding: .7rem 1rem !important;
        font-size: 1.1rem !important;
    }

    .cmd-page-secondary-heading h1,
    .show-description-heading h1 {
        font-size: 2.8rem !important;
    }

    #captchaBox .captchaField {
        gap: 1.5rem !important;
    }
}

/*finished*/


/*media quiry for specific devices*/
@media screen and (max-width: 390px) and (max-height: 800px) {

    /*for contact page*/
    .contactUs_page-header {
        height: 100vh !important;
    }

    /*finished*/
}

/*finished*/