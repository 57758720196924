.about_page {
    margin-top: -5rem;
}

.about_us-container {
    /* border: 1px solid white; */
    padding-bottom: 5rem;
    position: relative;
}

.show-description-heading h1 {
    font-size: 3rem;
    color: white;
    text-align: center;
    text-shadow: 0 0px 10px rgba(255, 255, 255, 0.514);
    transform: translateY(10rem);
    opacity: 0;
    font-weight: bold;
}

.show-description-summary {
    width: 75%;
    margin: auto;
    margin-top: 2rem;
}

.show-description-summary p {
    transform: translateY(10rem);
    opacity: 0;
    color: rgb(231, 231, 231);
    font-size: 1.7rem;
    letter-spacing: 1px;
}

.about_us-rules {
    padding-top: 5rem   ;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about_us-rules-button {
    border-radius: .5rem;
    transform: translateY(10rem);
    opacity: 0;
}

.about_page-header img {
    object-position: top;
}

.about_us-rules.textTransformAnimation .about_us-rules-button,
.show-description-summary.textTransformAnimation p,
.show-description-heading.textTransformAnimation h1 {
    animation: textTransformAnimationY 1s forwards;
}

/* .show-description-heading.textTransformAnimation h1 {
    animation-delay: 1.5s;
} */