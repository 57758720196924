.padding-less {
    padding-bottom: 0;
}

.showProject-summary p:not(:first-child) {
    margin-top: 1.5rem;
    color: rgb(199, 197, 197);
    font-size: 1.4rem;
    line-height: 2.5rem;
}

.desc {
    color: rgb(199, 197, 197);
    font-size: 1.4rem;
}

.desc p {
    color: rgb(199, 197, 197);
    font-size: 1.4rem;
}

/* .showProject-summary p:first-child {
    font-size: 3rem;
    font-weight: bold;
    color: var(--base-color);
} */

.details .detail-heading {
    font-size: 3rem;
    font-weight: bold;
    color: var(--base-color);
}

.event-text-center p:first-child{
    color: white;
}

.showProject-summary p strong {
    color: white;
}

.project-item {
    overflow: hidden;
}

.project-item img,
.project-item video {
    width: 100%;
    height: 100%;
    opacity: .8;
    object-fit: cover;
    transition: all .3s;
}

.show-projects-item {
    margin-top: 5rem;
}

.project-item img:hover,
.project-item video:hover {
    opacity: 1;
    /* transform: scale(1.4); */
}

.project-item img:hover {
    transform: scale(1.4);
}

.makeLink a {
    cursor: pointer;
    color: white;
}

.inner-photos-style {
    padding: 1rem;
    position: absolute;
    bottom: 2rem;
    left: 0;
    background-color: rgba(0, 0, 0, 0.541);
    width: 100%;
    color: white;
    letter-spacing: .1rem;
    font-weight: 500;
}