/*
    here is navigating buttons like all, ongoing, completed!
*/

.ongoingCompleted_container {
    /* border: 1px solid white; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0rem 5rem 0rem;
}

.ongoingCompleted_container.textTransformAnimation .ongoingCompleted-buttons {
    animation: textTransformAnimationY 1s forwards;
}

.ongoingCompleted-buttons {
    display: flex;
    gap: 3rem;
    align-items: center;
    transform: translateY(10rem);
    opacity: 0;
    justify-content: center;
}

.ongoingCompleted-buttons button {
    border-radius: 5rem;
}