.responce-btn {
    padding: 1rem 2rem;
    outline: none;
    border: 2px solid var(--base-color);
    background-color: transparent;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    transition: all .3s;
    /* border-radius: .5rem; */
}

.responce-btn:hover {
    background-color: var(--base-color);
    box-shadow: 0px 0px 20px rgba(255, 237, 79, 0.4);
    color: #fff;
}

.focusBtn {
    background-color: var(--base-color);
    box-shadow: 0px 0px 20px rgba(255, 237, 79, 0.4);
    color: #fff;
}