.work-display-content {
    width: 100%;
    height: max-content;
    position: relative;
    /* border: 1px solid white; */
}

.work-display-item-grid {
    display: grid;
    /* border: 1px solid white; */
    grid-template-columns: repeat(auto-fit, minmax(55rem, 55rem));
    padding: 0rem 10rem 10rem 10rem;
    place-content: start;
    gap: 3rem;
    justify-content: center;
}

.work-display-item {
    height: 35rem;
    position: relative;
    transform: translateY(5rem);
    transition: transform .2s;
    opacity: 0;
}

.work-display-item_img {
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.work-display-item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: .5;
    transition: all .2s
}

.work-display-item-text {
    position: absolute;
    bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.432);
    left: 0%;
    filter: drop-shadow(0px 0px 20px rgb(43, 43, 43));
    padding: 2rem 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.work-display-item-text h1 {
    color: var(--base-color);
    font-size: 2.5rem;
    white-space: wrap;
    line-height: 2.4rem;
    /* text-shadow: 1px 1px 5rem black; */
}
.work-display-item.textTransformAnimation {
    animation: textTransformAnimationY 1s forwards;
}

.work-display-item.textTransformAnimation >* :nth-child(-n+) {
    animation-delay: 1s;
}

.work-display-item:hover .work-display-item_img img {
    opacity: 1;
    transform: scale(1.4);
}

