.footer {
    position: relative;
    margin-top: 10rem;
    bottom: 0;
    width: 100%;
    background: #efb630;
    min-height: 40rem;
}

.wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url("./bcImages/wave.png");
    background-size: 1000px 100px;
}

.wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate 4s linear infinite !important;
}

.wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
}

.footer_quicklinks {
    /* width: 80%; */
    /* height: ; */
    /* border: 1px solid white; */
    margin: auto;
}

.quicklink-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    /* border-bottom: 1px solid black; */
}

.quicklink-logo_box a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: black;
    /* border: 1px solid black; */
    padding-top: 1rem;
}

.quicklink-logo svg {
    transition: transform .3s;
    width: 50px;
    height: 50px ;
}

.quicklink-logo_box:hover  .quicklink-logo svg {
    transform: translateY(-1rem);
}

.quicklink-logo_box-margin-left {
    margin-left: calc(5rem - 2.5rem);
}

.quicklink-logo-txt {
    letter-spacing: 1px;
    font-size: 1.6rem;
    /* border: 1px solid black; */
}

.downPart {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid white; */
}

.quickLinkHeading h3 {
    font-size: 2.7rem;
    text-align: left;
    border-bottom: 1px solid black;
    padding: 0rem 0rem 1rem 0rem;
    margin-bottom: 1rem;
    color: black;
    width: 90vw;
    letter-spacing: .2rem;
}

.footer_quicklink {
    width: 90vw;
    height: max-content;
    /* border: 1px solid black; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.footer_quicklink ul {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
    /* border: 1px solid white; */
    padding: 2rem 3rem;
    border-radius: .5rem;
}

.footer_quicklink ul li a,
.fourthRow li {
    /* font-family: Poppins; */
    font-weight: 500;
    color: rgb(34, 34, 34);
    font-size: 1.1rem;
    letter-spacing: 1px;
    transition: transform .1s;
    display: inline-block;
}

.footer_quicklink ul li a:hover {
    transform: translateY(-.2rem) scale(1.05, 1.05);
}

.fourthRow .contact-area {
    font-size: 1.6rem;
    border-bottom: 1px solid black;
}

.fourthRow {
    width: 35rem;
    line-height: 1.8rem;
}

.footer_company_provirder {
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 0; */
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    font-size: 1.2rem;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(0, 0, 0); */
    background-color: #040406;
    letter-spacing: 2px;
}

@keyframes animateWaves {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-position-x: 0px;
    }
}

@keyframes animate {
    0% {
        background-position-x: -1000px;
    }

    100% {
        background-position-x: 0px;
    }
}

.social-link-follow a i {
    font-size: 2.5rem;
}

.social-link-follow a:not(:first-child) {
    margin-left: 1rem;
}