@media screen and (min-width: 1401px) {
    .md-content {
        width: 120rem;
    }

    .work-display-item-grid {
        padding: 0 0rem 10rem 0;
    }

    .contactUs_header-text {
        width: 50%;
    }

    .contact_content {
        justify-content: center;
    }

    .tender-data-container,
    .team-data,
    .other-notice,
    .job-guideline-box,
    .team-data {
        width: 140rem;
    }

    .show-description-summary,
    .showProduct-secondary-image {
        width: 130rem;
    }

    .home-about_cmd-down {
        width: 90%;
        margin: 5rem auto 0px auto;
    }

    .slider .list .item .content .title,
    .slider .list .item .content .type,
    .cmd-page-secondary-heading h1,
    .work-display_header-heading,
    .show-description-heading h1,
    .contactUs_header-sub-heading1,
    .contactUs_header-heading {
        font-size: 4rem;
    }

    .work-display_header-sub-heading1 {
        font-size: 4rem;
    }

    .cmd-brand-container {
        height: 10rem;
    }
}