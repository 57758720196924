.contactUs_page {
    position: relative;
    /* border: 1px solid white; */
}

.contactUs_page-header {
    height: 100vh;
    position: relative;
}

.contact_content {
    position: absolute;
    width: 75%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid white; */
    gap: 5rem;
}

.contactUs_header-img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    /* border: 1px solid white; */
}

.contactUs_header-text {
    text-align: start;
    width: calc(100% - 40rem);
    padding: 0;
}

.contactUs_header-sub-heading1,
.contactUs_header-heading {
    font-size: 4rem;
    /* white-space: nowrap; */
    font-weight: bold;
}

.contactUs_header-sub-heading1 {
    color: var(--base-color);   
    line-height: 5rem;
}

.contactUs_header-sub-heading2 {
    font-size: 1.4rem;
    padding-left: 0;
}

.contactUs_header-sub-heading2 span {
    color: var(--base-color);
    font-weight: bold;
    border-bottom: 2px solid var(--base-color);
    margin-right: 1rem;
    text-transform: uppercase;
    font-size: 1.5rem;
}

.contactUs_header-heading,
.contactUs_header-sub-heading2 {
    color: white;
}

.contact-form {
    width: 40rem;
    /* border: 1px solid white; */
}

.contact-form-container {
    width: 100%;
    /* border: 1px solid red; */
    transform: translateY(100%);
    animation: textTransformAnimationY 1s forwards;
}

.contactUs_header-sub-links {
    margin-top: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 2rem;
}

.contactUs_header-sub-links a {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(5rem);
    opacity: 0;
    background-color: var(--base-color);   
    color: white;
    border: 4px solid rgb(252, 252, 252);
    animation: textTransformAnimationY 1s 1.5s forwards;
    transition: color .2s;
} 

.contactUs_header-sub-links a:hover {   
    color: black;
}

.contactUs_header-sub-links a i {
    font-size: 1.8rem;
}

.contactUs_header-sub-links a:nth-child(2) {
    animation-delay: 2s;
}

.contactUs_header-sub-links a:nth-child(3) {
    animation-delay: 2.5s;
}

.formBox {
    display: flex;
    flex-direction: column;
    gap: .5rem;  
    width: 100%;
}

.formBox input {
    height: 5rem;
}

.formBox input,
textarea {
    width: 100%;
    padding: 0rem 1.5rem;
    outline: none;
    border: none;
    font-family: Poppins;
    background-color: black;
    font-size: 1.3rem;
    color: white;
    border-radius: .5rem;
    border-bottom: 2px solid var(--base-color);
} 

.formBox input::placeholder,
textarea::placeholder {
    color: white;
}

.show-captcha {
    display: inline-block;
    background: url("./bcImages/captha.jpeg");
    border: 1px solid rgb(216, 216, 216);
    font-size: 2rem;
    padding: .8rem 1.5rem;
    letter-spacing: .3rem;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

textarea {
    padding: 1rem 1.5rem;
}

.captchaField {
    gap: 2rem;
}

#captchaBox,
.captchaField {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.EnterCodeField {
    width: 40%;
}

.contact-submit-btn {
    width: 15rem !important;
    transition: all .2s !important;
    border: 3px solid var(--base-color) !important;
}
 
.sent-msg {
    padding: 2rem 1.5rem;
    font-size: 1.4rem;
    font-weight: bold;
}

.clickTorefresh {
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid var(--base-color);
    color: var(--base-color);
    transition: all .2s;
    white-space: nowrap;
    /* margin-right: 1rem; */
}

.clickTorefresh:hover {
    text-shadow: 0px 0px 1rem var(--base-color), 0px 0px 2rem yellow;
    transform: scale(1.05, 1.05);
}

.clickTorefresh:active {
    transform: scale(1, 1);
}

.submitCont {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    position: relative;
}

.submitCont button {
    padding: 0;
    height: 5rem;
    border-radius: .3rem;
    
}

.animation-buffer1,
.animation-buffer2,
.animation-buffer3  {
    width: 15rem;
    height: 5rem;
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    border-radius: .3rem;
    justify-content: center;
    border: 2px solid var(--base-color);
}

.animation-buffer2  {
    border: 2px solid orangered;
}

.animation-buffer3 {
    border: 2px solid green;
    color: green;
}

.animation-buffer1 span {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border-bottom: 2px solid var(--base-color);
    animation: animate-buffer 1s  infinite;
}

.animation-buffer2 p{
    color: white;
    font-size: 1.2rem;
    color: orangered;
    font-weight: bold;
}

@keyframes animate-buffer {
    to {
        transform: rotate(360deg);
    }
}

@keyframes animate-contact-text {
    0% {
        transform: translateY(5rem);
        opacity: 0;
    } 
    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}