:root {
    --base-color: #efb630;
    --bc-black-color: #000;
    --color-white: #eee;
    --radius-container: 20px;
    --primary-heading-color: white;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    scroll-behavior: smooth;
}

html {
    font-size: 62.5%;
}

a{
    cursor: pointer;
}

.work-display-item {
    cursor: pointer;
}

a,
li {
    list-style: none;
    text-decoration: none;
}

body {
    font-size: 1.6rem;
    font-family: Poppins;
}

.main_container {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 100vw;
    background-color: #040406;
    height: 100vh;
}

.display-margin-top-5rem {
    margin-top: -5rem;
}

.main_container::-webkit-scrollbar {
    width: 6px;
}

.main_container::-webkit-scrollbar-track {
    background: rgb(31, 31, 31);
    border-radius: 9px;
}

.main_container::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to top, orangered, yellow);
    border-radius: 9px;
}

button,
a {
    cursor: pointer;
}

/*all text set to justify*/
.text-justify {
    text-align: justify;
}

.letter-spacing-2px {
    letter-spacing: 2px;
}

.letter-spacing-1px {
    letter-spacing: 1px;
}

.footer_company_provirder a {
    font-weight: bolder;
    color: var(--base-color);
}

button {
    -webkit-tap-highlight-color: transparent;
}

.position-relative {
    position: relative;
}

.custom-no-data-found {
    color: white;
    text-align: center;
    padding: 3rem;
    font-size: 3rem;
}

.searchContainer {
    /* padding: 1rem 0rem 3rem 0rem; */
    /* padding-bottom: 3rem; */
    /* border: 1px solid white; */
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.searchContainer input {
    background-color: transparent;
    border: none;
    padding: 1rem;
    outline: none;
    color: white;
    font-size: 1.7rem;
    width: 90%;
    font-weight: 600;
}

.serachBox {
    width: 35rem;
    border: 2px solid var(--base-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5rem;
    overflow: hidden;
    padding-right: 1rem;
}

.searchContainer input::placeholder {
    color: white;
    font-size: 1.7rem;
    font-weight: 600;
}

.searchContainer i {
    color: white;
    transition: color .1s;
    font-size: 1.8rem;
}

.searchContainer i:hover {
    color: var(--base-color);
}