.header {
    position: sticky;
    top: 0;
    z-index: 1011;
}

.cmd-brand-container  {
    height: 8rem;
}

.cmd-brand-container img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    object-position: center;
    /* transform: scale(1, 1); */
}

.cmd-brand-right {
    /* width: max-content; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solrgb(41, 26, 26)ite; */
}
.cmd-brand-name {
    font-size: 3.8rem;
    color: var(--base-color);
    color: white;
    font-weight: bolder;
    font-family: 'Bremen', sans-serif;
    white-space: nowrap;
}

.cmd-brand-text1 {
    font-size: 1rem;
    font-weight: 600;
    /* width: 100rem; */
    /* border: 1px solid white; */
    text-align: justify;
    /* margin-top: -.5rem; */
}

.navbar {
    width: 100%;
    height: 5rem;
    background-color: rgba(60, 60, 60, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.589);
    padding: 0rem 1.5rem;
    transition: background .3s;
}

.nav_left {
    /* border: 1px solid white; */
    display: none;
    align-items: center;
}

.nav_left img {
    width: 10rem;
    /* filter: drop-shadow(0px 0px 20px white); */
}

.navigate-navbar {
    /* border: 1px solid white; */
    position: relative;
    width: 35px;
    height: 35px;
    background-color: rgba(114, 114, 114, 0.24);
    border-radius: 3px;
    display: none;
    cursor: pointer;
    transition: all .3s;
}

.navigate-navbar:hover {
    background-color: rgba(114, 114, 114, 0.884);
}

.navigate-navbar i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* font-size: 2rem; */
    color: white;
    padding: 1rem;
    /* background-color: rgba(0, 0, 0, 0.651); */
    transition: opacity .3s;
}

.fa-xmark {
    opacity: 0;
    z-index: 0;
}

.fa-bars {
    z-index: 1;
}

.navigativeBar .nav-inner-list_container:hover {
    cursor: pointer;
}

@media screen and (min-width: 1360px) {
    .nav_right {
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: .5rem;
    }

    .nav_right .nav_link a,
    .nav_right .nav-inner-list_heading {
        color: var(--color-white);
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: .1rem;
    }

    .nav_right .nav_link a {
        display: inline-block;
        background-color: #1b1b1b28;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .7rem 1.3rem;
        position: relative;
        filter: drop-shadow(0px 0px .3rem rgb(133, 133, 133));
        border-radius: .3rem;
        overflow: hidden;
    }

    .nav_right .nav_link a::before,
    .nav_right .nav-inner-list_container::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        background-color: var(--base-color);
        border-radius: .3rem;
        z-index: -1;
        transition: width .3s;
    }

    .nav_right .nav_link a:hover::before,
    .nav_right .nav-inner-list_container:hover::before {
        width: 100%;
    }

    .nav_right .nav_link.focusAnimation a::before,
    .nav_right .nav-inner-list_container.focusAnimation::before {
        width: 100%;
    }

    .nav_link.focusAnimation a {
        color: black;
    }

    .nav-inner-list_container.focusAnimation div span,
    .nav-inner-list_container.focusAnimation div i,
    .nav-inner-list_container.focusAnimation ul li.focusAnimation a {
        color: black;
    }

    .nav_right .nav-inner-list_container {
        background-color: #1b1b1b28;
        filter: drop-shadow(0px 0px .3rem rgb(133, 133, 133));
        border-radius: .3rem;
        font-size: 1.3rem;
        position: relative;
    }

    .nav_right .nav-inner-list_container>div {
        padding: .7rem 1.3rem;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        /* border: 1px solid black; */
        border-radius: .3rem;
    }

    .nav_right .nav-inner-list {
        display: none;
        background-color: rgba(0, 0, 0, 0.507);
        position: absolute;
        min-width: 130%;
        right: 0;
        top: 0;
        transform: translateY(3.4rem);
        height: max-content;
        box-shadow: .1rem .1rem .3rem rgba(34, 34, 34, 0.473);
        border-radius: 3px;
    }

    .nav_right .nav-inner-list_container:hover span i {
        transform: rotate(180deg);
    }

    .nav_right .nav-inner-list_container:hover .nav-inner-list {
        display: block;
    }

    .nav_right .nav-inner-list_container li {
        overflow: hidden;
    }

    .nav_right .nav-inner-list li a {
        display: inline-block;
        width: 100%;
        padding: 1rem;
        color: var(--color-white);
        border-bottom: 2px solid white;
        font-weight: 500;
        letter-spacing: 1px;
        white-space: nowrap;
        display: inline-block;
        position: relative;
        border-radius: 3px;
    }

    .nav_right .nav-inner-list li a::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 0%;
        height: 100%;
        z-index: -1;
        background-color: var(--base-color);
        transition: width .2s ease-out;
        border-radius: 3px;
    }

    .nav_right .nav-inner-list li.focusAnimation a::before {
        width: 100%;
    }

    .nav_right .nav-inner-list li a:hover::before {
        width: 100%;
    }

}

.nav-inner-list_arrow {
    color: var(--color-white);
    margin-left: .5rem;
    transition: transform .3s;
}

@media screen and (max-width: 1359px) {
    .burgerMenu_container {
        position: absolute;
        top: 0;
        left: 0;
        /* z-index: 1002; */
        /* background-color: rgba(0, 0, 0, 0.925); */
        background-image: url("./bcImages/menubg.jpg");
        background-position: center;
        background-size: cover;
        width: 0vw;
        overflow-x: hidden;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        /* padding: 5rem; */
        justify-content: center;
        /* border: 1px solid red; */
        transition: height .3s;
        height: calc(100vh - 10rem);

    }

    .burgerMenu_container.hamburger_animation {
        animation: animate_burgerMenu_container 1s forwards;
    }

    .burgerMenu_container li div {
        display: flex;
        justify-content: center;
    }

    .nav-inner-list {
        display: none;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .burgerMenu_container .nav_link a,
    .burgerMenu_container li div {
        color: white;
        font-size: 2.5rem;
        /* text-shadow: 0px 0px 3px black; */
        font-weight: 200;
        transition: color .3s;
    }

    .nav-inner-list_container.makeFocus ul {
        display: flex;
        /* font-size: 1.5rem; */
    }

    .nav-inner-list {
        display: none;
        background-color: var(--base-color);
        padding: 1rem;
        border-radius: .5rem;
    }

    .nav-inner-list li a {
        color: white;
        font-size: 1.3rem;
        transition: color .3s;
    }

    .burgerMenu_container li a:hover,
    .burgerMenu_container li div:hover {
        color: black;
    }

    .burgerMenu_container li div .fa-chevron-down {
        display: none;
    }

    .navigate-navbar {
        display: flex !important;
    }

    .nav_left {
        display: flex !important;
    }

    .navbar {
        /* align-items: center !important; */
        justify-content: space-between !important;
    }
}

@keyframes animate_burgerMenu_container {
    to {
        width: 100vw;
        overflow-y: auto;
    }
}

/* .navigativeBar {
    display: none;
} */

.notice a {
   display: flex;
   align-items: center;
   justify-content: space-between;
   /* border: 1px solid white;
   padding: 0; */
}
/*
.notice a img {
    position: absolute;
    top: 0;
    right: -.5rem;
} */