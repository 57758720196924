.showProduct_page {
    position: relative;
    padding-bottom: 10rem;
}

.showProduct_page-inner-container {
    height: 100vh;
    width: 100vw;
    position: relative;
    /* border: 2px solid yellow; */
}

/* .showProduct-img */

.showProduct_page-header {
    position: relative;
}

.showProduct_page-header .showProduct_header-img {
    object-position: center;
}

.showProduct-secondary-image {
    width: 80%;
    height: max-content;
    margin: 3rem auto 0rem auto;
    /* border: 1px solid white; */
}

.showProduct-secondary-image img {
    width: 100%;
    height: max-content;
    border-radius: 5rem;
    transform: translateY(10rem);
    opacity: 0;
}

.showProduct-secondary-image.textTransformAnimation img {
    animation: textTransformAnimationY 1s forwards;
}

.productNavigateBtn {
    position: fixed;
    top: 50%;
    z-index: 1001;
    width: 20rem;
    height: 10rem;
    display: flex;
    align-items: start;
    transition: transform .4s;
    /* background-color: red; */
    filter: drop-shadow(0px 0px 10px rgba(128, 128, 128, 0.4));
}

.productNavigateBtn .nav-btn-box1 {
    width: 85%;
    height: 80%;
    /* border: 1px solid red; */
}


.productNavigateBtn .nav-btn-box2 {
    width: 15%;
    display: grid;
    place-items: center;
    background-color: black;
    height: 80%;
}

.nav-btn-box1 {
    /* border: 1px solid red; */
    position: relative;
}

.nav-btn-box1-left img {
    position: absolute;
    left: 0;
    height: 100%;
}

.nav-btn-box1-right img {
    position: absolute;
    right: 0;
    height: 100%;
}

.left-btn {
    left: 0;
    transform: translateX(-85%) translateY(-50%);
}

.productNavigateBtn:hover {
    transform: translateX(0) translateY(-50%);
}

.right-btn {
    right: 0;
    transform: translateX(85%) translateY(-50%);
}
 
.btn-left,
.btn-right {
    padding: 0;
    background-color: black;
    color: white;
}

.btn-title {
    position: absolute;
    bottom: 0;
    font-size: 1.2rem;
    width: 100%;
    letter-spacing: 2px;
    text-align: center;
    height: 20%;
    /* margin-top: 1rem; */
    display: grid;
    color: white;
    place-items: center;
    /* font-weight: bold; */
}