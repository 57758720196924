.slider {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.notice-content {
    display: flex;
    gap: 5rem;
}

.live-notice-group {
    display: flex;
    align-items: center;
    gap: .2rem;
    filter: drop-shadow(1px 1px 3px black);
    transition: transform .2s;
}

.live-notice-group:hover {
    transform: scale(1.04);
}

.live-notice-group button {
    background-color: transparent;
    color: white;
    outline: none;
    border: none;
    font-size: 1.5rem;
}

.marquee-container {
    position: absolute;
    left: 0;
    top: 5rem;
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
    background-color: rgba(0, 0, 0, 0.13);
    z-index: 1001;
    box-shadow: 0rem 0rem 3rem rgba(0, 0, 0, 0.336);
}

.slider .list .item {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
}

.gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #040406 2%, #0404062f, transparent);
    left: 0;
    bottom: 0;
}

.slider .list .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider .list .item .content {
    position: absolute;
    top: 20%;
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 30%;
    color: #fff;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.842);
}

.slider .list .item .content .title,
.slider .list .item .content .type {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.3em;
    /* white-space: nowrap; */
    /* white-space: nowrap; */
}

.slider .list .item .type {
    color: var(--base-color);
}

.slider .list .item .button {
    display: grid;
    grid-template-columns: repeat(2, 12rem);
    grid-template-rows: 4rem;
    gap: 5px;
    margin-top: 2rem;
}

.slider .list .item .button a {
    width: 12rem;
    height: 4rem;
    display: inline-block;
    font-size: 1.3rem;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.slider .list .item .button a:hover {
    background-color: var(--base-color);
    box-shadow: 0px 0px 20px rgba(255, 237, 79, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

.slider .list .item .button a:nth-child(2) {
    background-color: red;
    border: 1px solid #fff;
    color: #eee;
}

.thumbnail {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    width: max-content;
    z-index: 101;
    display: flex;
    gap: 2rem;
}

.thumbnail .item {
    width: 15rem;
    height: 22rem;
    flex-shrink: 0;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    transition: all ease-in .2s;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.thumbnail .item:hover {
    transform: scale(1.05, 1.05);
    opacity: 1;
}

.thumbnail .item:last-child {
    opacity: 1;
    box-shadow: rgba(53, 53, 53, 0.521) 0px 7px 29px 0px;
}

.thumbnail .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all ease-in-out .4s;
}

.thumbnail .item:hover img {
    transform: scale(1.2, 1.2);
}

.nextPrevArrows {
    position: absolute;
    top: 80%;
    right: 52%;
    z-index: 100;
    width: 30rem;
    max-width: 30%;
    display: flex;
    gap: 5px;
    align-items: center;
    /* border: 1px solid white; */
}

.nextPrevArrows button i {
    font-size: 1.1rem;
    color: black;
    transition: color .3s;
}

.nextPrevArrows button {
    width: 3.3rem;
    height: 3.3rem;
    border-radius: 4px;
    background-color: #eee;
    border: none;
    color: #fff;
    font-family: monospace;
    font-weight: bold;
    transition: .5s;
    cursor: pointer;
}

.nextPrevArrows button:hover {
    background-color: var(--base-color);
    color: #000;
}

.nextPrevArrows button:hover i {
    color: white;
}

.slider .list .item:nth-child(2) {
    z-index: 1;
}

.slider .list .item:nth-child(1) {
    z-index: 2;
}

.content {
    backdrop-filter: blur(1px);
}

.slider .list .item .content .description {
    letter-spacing: 1px;
    font-size: 1.3rem;
    /* text-align: justify; */
}

.slider .list .item:nth-child(1) .content .title,
.slider .list .item:nth-child(1) .content .type,
.slider .list .item:nth-child(1) .content .description,
.slider .list .item:nth-child(1) .content .button {
    transform: translateY(5rem);
    filter: blur(20px);
    opacity: 0;
    animation: showContent .5s 1s linear 1 forwards;
}

.slider .list .item:nth-child(1) .content .title {
    animation-delay: 0.4s !important;
}

.slider .list .item:nth-child(1) .content .type {
    animation-delay: 0.6s !important;
}

.slider .list .item:nth-child(1) .content .description {
    animation-delay: 0.8s !important;
}

.slider .list .item:nth-child(1) .content .button {
    animation-delay: 1s !important;
}

.slider.next .list .item:nth-child(1) img,
.slider.next .list .item:nth-child(1) .gradient {
    width: 15rem;
    height: 22rem;
    position: absolute;
    bottom: 5rem;
    left: 50%;
    border-radius: 30px;
    animation: showImage .5s linear 1 forwards;
}

.slider.next .thumbnail .item:nth-last-child(1) {
    overflow: hidden;
    animation: showThumbnail .5s linear 1 forwards;
}

.slider.prev .list .item img {
    z-index: 100;
}


.slider.next .thumbnail {
    animation: effectNext .5s linear 1 forwards;
}


/* Animation for prev button click */
.slider.prev .list .item:nth-child(2) {
    z-index: 2;
}

.slider.prev .list .item:nth-child(2) img {
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}

.slider.prev .thumbnail .item:nth-child(1) {
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail .5s linear 1 forwards;
}

.slider.next .nextPrevArrows button,
.slider.prev .nextPrevArrows button {
    pointer-events: none;
}

.slider.prev .list .item:nth-child(2) .content .title,
.slider.prev .list .item:nth-child(2) .content .type,
.slider.prev .list .item:nth-child(2) .content .description,
.slider.prev .list .item:nth-child(2) .content .button {
    animation: contentOut 1.5s linear 1 forwards !important;
}

/* here is the quick link box style*/

.home-noticeLink-container {
    position: absolute;
    height: 30rem;
    z-index: 999;
    width: 30rem;
    border-radius: 3px;
    top: 6rem;
    right: 2rem;
    /* opacity: 0; */
    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.719);
    overflow: hidden;
    background-color: #222831b4;
}

.quicklink-heading {
    color: white;
    text-align: start;
    text-shadow: 1px 1px 3px white;
    padding: 1rem 2rem;
    border-bottom: 2px solid var(--base-color);
    background-color: #171a1fb9;
}

.quicklinks-container {
    background-color: #222831a4;
    height: calc(30rem - 1.6rem - 2rem);
    /* margin-top: 1rem; */
    overflow-y: auto;
}

.quicklinks-container li button {
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: transparent;
    outline: none;
    border: none;
    gap: 1rem;
    color: white;
    transition: transform .2s;
}

.quicklinks-container li:hover button {
    transform: translateY(-.5rem);
}

.quicklinks-container li button p {
    filter: drop-shadow(0px 0px 10px black);
    font-size: 1.5rem;
}

.quicklinks-container li {
    padding: 1rem;
}

.circle-style {
    width: 7px;
    height: 7px;
    background-color: white;
    border-radius: 50%;
}

/*here is quicklink box style closed*/


/*here is cmd about text and recent project showcase style*/
.home-about_cmd_container {
    margin-top: 5rem;
    padding: 0rem 5rem;
    color: white;
}

.cmd-page-secondary-heading h1 {
    text-align: center;
    font-size: 3.5rem;
    letter-spacing: 2px;
    transform: translateY(10rem);
    opacity: 0;
    color: #fff;
    font-weight: bold;
    text-shadow: 0 0px 10px rgba(255, 255, 255, 0.514);
    /* border: 1px solid white; */
}

.home-about_cmd-down {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-top: 5rem;
}

.home-about_cmd-left,
.home-about_cmd-right {
    width: 50%;
    margin-top: 0rem;
    opacity: 0;
    transform: translateX(-100%);
}

.home-about_cmd-right {
    height: max-content;
    transform: translateX(100%);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-about_cmd_container.textTransformAnimation .home-about_cmd-down .home-about_cmd-left {
    animation: textTransformAnimationX 1s forwards;
}

.home-about_cmd_container.textTransformAnimation .home-about_cmd-down .home-about_cmd-right {
    animation: textTransformAnimationX 1s forwards;
    animation-delay: 1s;
}

.home-about_cmd_container.textTransformAnimation .home-about_cmd-up h1,
.home-recent_projects-heading.textTransformAnimation h1 {
    animation: textTransformAnimationY .5s forwards;
}

.home-about_cmd-right img {
    width: 80%;
    height: max-content;
    fill: transparent;
}

.home-about_cmd-left p {
    letter-spacing: 1px;
    font-size: 1.8rem;
}

.home-recent-project-showcase,
.home-recent_projects {
    margin-top: 5rem;
    position: relative;
}

.display-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-not-found {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    /* border: 4px solid white; */
}